<template>
   <div class="pack">
  <p v-if="step.id_parcela"><span class="description">Označení parcely</span><span class="text">{{ step.id_parcela }}</span></p>
  <p v-else><span class="description">Označení parcely</span><span class="text">-</span></p>

  <p v-if="step.celkova_plocha"><span class="description">Celková plocha</span><span class="text">{{ step.celkova_plocha }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Celková plocha</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.inzsite"><span class="description">Inženýrské sítě</span><span class="text">{{ getInziste(step.inzsite) }}</span></p>
  <p v-else><span class="description">Inženýrské sítě</span><span class="text">-</span></p>

  <p v-if="step.odpad"><span class="description">Odpad</span><span class="text">{{ getOdpad(step.odpad) }}</span></p>
  <p v-else><span class="description">Odpad</span><span class="text">-</span></p>
  </div>
</template>

<script>
import ciselniky from "../../../libs/ciselniky";
export default {
  name: "zadanyTyp-3",
  props:['step'],
  methods: {
    getInziste(id){
      return ciselniky.inziste[id];
    },
    getOdpad(id){
      return ciselniky.odpad[id];
    }
  }
}
</script>

<style scoped lang="less">
section.home-content{
  .container{
    padding: 0 40px;
    p{
      text-align: left;
      margin-bottom: 2rem;
      .description{
        font-weight: 700;
        color: #03A3BD;
      }
      .text{
        display: block;
      }
    }
    .pack{
      p{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>