<template>
  <div class="pack">
  <p v-if="step.cislo_jednotky"><span class="description">Číslo jednotky</span><span class="text">{{ step.cislo_jednotky }}</span></p>
  <p v-else><span class="description">Číslo jednotky</span><span class="text">-</span></p>

  <p v-if="step.pater"><span class="description">Počet pater</span><span class="text">{{ step.pater }}</span></p>
  <p v-else><span class="description">Počet pater</span><span class="text">-</span></p>


  <p v-if="step.patro"><span class="description">Patro</span><span class="text">{{ step.patro }}</span></p>
  <p v-else><span class="description">Patro</span><span class="text">-</span></p>

  <p v-if="step.uzit_plocha"><span class="description">Užitná plocha</span><span class="text">{{ step.uzit_plocha }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Užitná plocha</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.stav"><span class="description">Stav</span><span class="text">{{ getStav(step.stav) }}</span></p>
  <p v-else><span class="description">Stav</span><span class="text">-</span></p>

  <p v-if="step.postavenoz"><span class="description">Postaveno z</span><span class="text">{{ getPostavenoz(step.postavenoz) }}</span></p>
  <p v-else><span class="description">Postaveno z</span><span class="text">-</span></p>

  <p v-if="step.vytah"><span class="description">Výtah</span><span class="text">{{ getVytah(step.vytah) }}</span></p>
  <p v-else><span class="description">Výtah</span><span class="text">-</span></p>

  <p v-if="step.vytapeni"><span class="description">Vytápění</span><span class="text">{{ getVytapeni(step.vytapeni) }}</span></p>
  <p v-else><span class="description">Vytápění</span><span class="text">-</span></p>

  <p v-if="step.plocha_sklep"><span class="description">Plocha sklepu</span><span class="text">{{ step.plocha_sklep }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha sklepu</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.plocha_lodzie"><span class="description">Plocha lodžie</span><span class="text">{{ step.plocha_lodzie }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha lodžie</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.pocet_garaz"><span class="description">Plocha garáže</span><span class="text">{{ step.pocet_garaz }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha garáže</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.plocha_balkon"><span class="description">Plocha balkónu</span><span class="text">{{ step.plocha_balkon }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha balkónu</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.plocha_terasa"><span class="description">Plocha terasy</span><span class="text">{{ step.plocha_terasa }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha terasy</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.park_pocet"><span class="description">Počet parkovacích míst</span><span class="text">{{ step.park_pocet }}</span></p>
  <p v-else><span class="description">Počet parkovacích míst</span><span class="text">-</span></p>
  </div>
</template>

<script>
import ciselniky from "../../../libs/ciselniky";

export default {
  name: "zadanyTyp-4",
  props:['step'],
  methods: {
    getStav(id){
      return ciselniky.stav[id];
    },
    getPostavenoz(id){
      return ciselniky.postavenoz[id];
    },
    getVytapeni(id){
      return ciselniky.vytapeni[id];
    },
    getVytah(id){
      return ciselniky.vytapeni[id];
    }
  }
}
</script>

<style scoped lang="less">
section.home-content{
  .container{
    padding: 0 40px;
    p{
      text-align: left;
      margin-bottom: 2rem;
      .description{
        font-weight: 700;
        color: #03A3BD;
      }
      .text{
        display: block;
      }
    }
    .pack{
      p{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>