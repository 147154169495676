<template>
  <div>
    <h3>Statistiky</h3>
    <div v-if="loading" class="uk-flex uk-flex-center">
      <div uk-spinner="ratio: 3"></div>
    </div>
    <p v-if="apiMsg">{{ apiMsg }}</p>
    <ul class="uk-list uk-list-large">
      <li v-for="stat in list" :key="stat.rozsah">
        <a :href="stat.url" target="_blank">{{ stat.rozsah }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "statistics",
  props: ["remoteId"],
  async created() {
    await this.getList();
    this.loading = false;
  },
  data() {
    return {
      token: null,
      list: [],
      apiMsg: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["tokenIsValid", "getToken"]),
  },
  methods: {
    async getList() {
      this.token = this.tokenIsValid
        ? this.getToken
        : await this.$store.dispatch("getToken").then((token) => (this.token = token));
      console.log(this.token);
      console.log(this.remoteId);
      await this.axios.post("statistiky/" + this.remoteId + "/", { token: this.token }).then((response) => {
        console.log(response);
        if (response.data.status == "OK") {
          let stats = response.data.info.stats;
          // test
          // this.parseData(stats);
          // production

          if (!stats) {
            this.apiMsg = "Zatím zde nejsou žádné statistiky";
          } else {
            this.parseData(stats);
          }
        } else {
          this.apiMsg = response.data.info.name;
        }
      });
    },
    parseData(realData) {
      let data = {
        "2021-05-06--2021-06-06": "https://www.ekrksoft.cz/statistiky/statistiky-31936x394279611683971.html",
        "2021-04-05--2021-05-05": "https://www.ekrksoft.cz/statistiky/statistiky-31936x469147842555756.html",
        "2021-03-04--2021-04-04": "https://www.ekrksoft.cz/statistiky/statistiky-31936x472616688896466.html",
        "2021-01-31--2021-03-03": "https://www.ekrksoft.cz/statistiky/statistiky-31936x894687538727495.html",
        "2020-12-30--2021-01-30": "https://www.ekrksoft.cz/statistiky/statistiky-31936x436139322458273.html",
        "2020-11-29--2020-12-29": "https://www.ekrksoft.cz/statistiky/statistiky-31936x622272925848576.html",
        "2020-10-28--2020-11-28": "https://www.ekrksoft.cz/statistiky/statistiky-31936x829553355415427.html",
        "2020-09-27--2020-10-27": "https://www.ekrksoft.cz/statistiky/statistiky-31936x761742511364989.html",
      };

      if (realData) {
        data = realData;
      }

      Object.entries(data).forEach((row) => {
        const [rozsah, url] = row;
        const startDate = new Date(rozsah.slice(0, 10)).toLocaleDateString("cs-CZ");
        const endDate = new Date(rozsah.slice(12, 23)).toLocaleDateString("cs-CZ");
        console.log(startDate + " - " + endDate);
        let listRow = {
          rozsah: startDate + " - " + endDate,
          url: url,
        };
        this.list.push(listRow);
      });
    },
  },
};
</script>

<style lang="less" scoped>
h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 2rem;
  line-height: 7.6rem;
  text-align: center;
  color: #7c7c7b;
  margin-top: 0;
  margin-bottom: 0.1rem;
}

p {
  text-align: center;
  font-size: 1.5rem;
}
ul {
  text-align: center;
  li {
    margin-top: 0;
    margin-bottom: 1rem;
    a {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #2c3e50;
    }
  }
}
</style>
