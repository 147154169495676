<template>
  <section class="home-content">
    <div class="container">
      <h1>{{ add.steps[1].nazev }}</h1>

      <div class="slider" data-uk-slider>
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
          <ul class="uk-slider-items uk-child-width-1-1">
            <li v-for="image in images" :key="image.dataid">
              <div class="picture">
                <picture>
                  <img :src="image.base" alt="" />
                </picture>
              </div>
            </li>
          </ul>
          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>

      <div class="uk-flex uk-flex-center uk-margin-bottom uk-flex-between">
        <router-link :to="{ name: 'changeadvert', params: { id: add.id, requestType: 1 } }" class="button white small"
          >upravit inzerát</router-link
        >
        <router-link :to="{ name: 'changeadvert', params: { id: add.id, requestType: 2 } }" class="button white small"
          >zrušit inzerát</router-link
        >
      </div>

      <h3>Krok 1</h3>
      <p>
        <span class="description">Název</span><span class="text">{{ add.steps[1].nazev }}</span>
      </p>
      <p>
        <span class="description">Popis</span><span class="text">{{ add.steps[1].popis_cz }}</span>
      </p>
      <div class="pack">
        <p>
          <span class="description">Vlastnictví</span
          ><span class="text">{{ getVlastnictvi(add.steps[1].vlastnictvi) }}</span>
        </p>
        <p>
          <span class="description">Zadaný typ</span><span class="text">{{ getTyp(add.steps[1].zadanytyp) }}</span>
        </p>
        <p>
          <span class="description">Druh</span><span class="text">{{ getDruh(add.steps[1].druh) }}</span>
        </p>
      </div>

      <h3>Adresa</h3>
      <p>
        {{ add.address.place }} <br />
        {{ add.address.street }} {{ add.address.cp }}{{ add.address.co }} <br />
        {{ add.address.city }} {{ add.address.distName }}
      </p>

      <h3>Krok 2</h3>
      <div class="pack">
        <p>
          <span class="description">Cena nemovitosti</span><span class="text">{{ formatedPrice }}</span>
        </p>
        <p>
          <span class="description">Měna</span><span class="text">{{ getMeny(add.steps[2].mena) }}</span>
        </p>
        <p>
          <span class="description">Dodatek k ceně</span><span class="text">{{ add.steps[2].cenatext }}</span>
        </p>
        <p v-if="add.steps[2].energ">
          <span class="description">Penb - Průkaz energetické náročnosti budovy</span
          ><span class="text">{{ getEnerg(add.steps[2].energ) }}</span>
        </p>
      </div>
      <zadany-typ2 v-if="add.steps[1].zadanytyp == 2" :step="add.steps[2]"></zadany-typ2>
      <zadany-typ3 v-if="add.steps[1].zadanytyp == 3" :step="add.steps[2]"></zadany-typ3>
      <zadany-typ4 v-if="add.steps[1].zadanytyp == 4" :step="add.steps[2]"></zadany-typ4>
      <zadany-typ6 v-if="add.steps[1].zadanytyp == 6" :step="add.steps[2]"></zadany-typ6>
      <zadany-typ11 v-if="add.steps[1].zadanytyp == 11" :step="add.steps[2]"></zadany-typ11>

      <h3>Faktické vady</h3>
      <div class="pack">
        <p v-if="add.steps[2].fakt01 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt01) }}Poškozená střecha</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt01) }}Poškozená střecha</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt02 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt02) }}Poškození nosných konstrukcí</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt02) }}Poškození nosných konstrukcí</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt03 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt03) }}Nadměrná (vzlinající) vlhkost</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt03) }}Nadměrná (vzlinající) vlhkost</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt04 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt04) }}Nefunkční elektroinstalace</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt04) }}Nefunkční elektroinstalace</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt05 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt05) }}Přerušený přívod vody/plynu</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt05) }}Přerušený přívod vody/plynu</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt07 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt07) }}Omezená přístupová cesta</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt07) }}Omezená přístupová cesta</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt09 == 1">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt09) }}Jiné</span><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getFaktickevady(add.steps[2].fakt09) }}Jiné</span><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].fakt10 != ''">
          <span class="description">{{ getFaktickevady(add.steps[2].fakt10) }}Jaké?</span
          ><span class="text">{{ add.steps[2].fakt10 }}</span>
        </p>
      </div>

      <h3>Právní vady</h3>
      <div class="pack">
        <p v-if="add.steps[2].pakt01 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt01) }}Dluh vůči společenství vlastníků</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt01) }}Dluh vůči společenství vlastníků</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt02 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt02) }}Exekuce</span><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt02) }}Exekuce</span><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt03 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt03) }}Insolvence</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt03) }}Insolvence</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt04 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt04) }}Předkupní právo</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt04) }}Předkupní právo</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt05 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt05) }}Hypotéka</span><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt05) }}Hypotéka</span><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt06 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt06) }}Jiné zástavní právo</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt06) }}Jiné zástavní právo</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt07 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt07) }}Zákaz převodu nemovitosti</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt07) }}Zákaz převodu nemovitosti</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt08 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt08) }}Spor o vlastnictví nemovitosti</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt08) }}Spor o vlastnictví nemovitosti</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt10 == 1">
          <span class="description">{{ getPravniVady(add.steps[2].pakt10) }}Jiné</span><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getPravniVady(add.steps[2].pakt10) }}Jiné</span><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].pakt11 != ''">
          <span class="description">{{ getPravniVady(add.steps[2].pakt11) }}Jaké?</span
          ><span class="text">{{ add.steps[2].pakt11 }}</span>
        </p>
      </div>

      <h3>Věcné břemeno</h3>
      <div class="pack">
        <p v-if="add.steps[2].cakt01 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt01) }}Služebnost inženýrské sítě</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt01) }}Služebnost inženýrské sítě</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt02 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt02) }}Opora cizí stavby</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt02) }}Opora cizí stavby</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt03 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt03) }}Právo na svod dešt. vody</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt03) }}Právo na svod dešt. vody</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt04 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt04) }}Služebnost stezky, průhonu a cesty</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt04) }}Služebnost stezky, průhonu a cesty</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt05 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt05) }}Právo pastvy</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt05) }}Právo pastvy</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt06 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt06) }}Užívací právo</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt06) }}Užívací právo</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt07 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt07) }}Používací právo</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt07) }}Používací právo</span
          ><span class="text">NE</span>
        </p>

        <p v-if="add.steps[2].cakt08 == 1">
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt08) }}Služebnost bytu</span
          ><span class="text">ANO</span>
        </p>
        <p v-else>
          <span class="description">{{ getVecneBremeno(add.steps[2].cakt08) }}Služebnost bytu</span
          ><span class="text">NE</span>
        </p>
      </div>

      <hr />
      <statistics :remoteId="add.remoteId" />
      <hr />
      <div class="uk-flex uk-flex-center uk-margin-bottom uk-flex-between">
        <router-link :to="{ name: 'changeadvert', params: { id: add.id, requestType: 1 } }" class="button white small"
          >upravit inzerát</router-link
        >
        <router-link :to="{ name: 'changeadvert', params: { id: add.id, requestType: 2 } }" class="button white small"
          >zrušit inzerát</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ciselniky from "../../libs/ciselniky";
import statistics from "@/components/viewonly/statistics";
import zadanyTyp2 from "@/components/viewonly/zadanyTyp-2";
import zadanyTyp3 from "@/components/viewonly/zadanyTyp-3";
import zadanyTyp4 from "@/components/viewonly/zadanyTyp-4";
import zadanyTyp6 from "@/components/viewonly/zadanyTyp-6";
import zadanyTyp11 from "@/components/viewonly/zadanyTyp-11";

export default {
  name: "viewonly",
  async created() {
    this.id = this.$route.params.id;
    let add = this.$store.getters.advertById(this.id);
    this.add = add;
    console.log(add.remoteId);
    let images = await this.getImages(add.id);
    for (const position in images) {
      let img = images[position];
      if (img.base) {
        this.images.push(img);
      }
    }

    if (add.steps[2].cena) {
      const formater = new Intl.NumberFormat("cs-CZ", {});
      this.formatedPrice = formater.format(add.steps[2].cena);
    }
  },
  data() {
    return {
      id: null,
      add: null,
      images: [],
      formatedPrice: "",
    };
  },
  computed: {
    ...mapGetters(["getImages"]),
  },
  components: {
    statistics,
    zadanyTyp2,
    zadanyTyp3,
    zadanyTyp4,
    zadanyTyp6,
    zadanyTyp11,
  },
  methods: {
    getVlastnictvi(id) {
      return ciselniky.vlastnictvi[id];
    },
    getDruh(id) {
      return ciselniky.druh[id];
    },
    getTyp(id) {
      return ciselniky.typ[id];
    },
    getMeny(id) {
      return ciselniky.meny[id];
    },
    getPravniVady(id) {
      return ciselniky.pravniVady[id];
    },
    getFaktickevady(id) {
      return ciselniky.faktickeVady[id];
    },
    getVecneBremeno(id) {
      return ciselniky.vecneBremeno[id];
    },
    getEnerg(id) {
      return ciselniky.energ[id];
    },
  },
};
</script>

<style scoped lang="less">
h2 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 3rem;
  line-height: 7.6rem;
  text-align: center;
  color: #7c7c7b;
  margin-top: 0;
  margin-bottom: 1rem;
}

h3 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 2rem;
  line-height: 7.6rem;
  text-align: center;
  color: #7c7c7b;
  margin-top: 0;
  margin-bottom: 0.1rem;
}

section.home-content {
  .container {
    padding: 0 40px;
    h1 {
      font-size: 3rem;
      line-height: 4rem;
      margin-top: 2rem;
    }
    p {
      text-align: left;
      margin-bottom: 2rem;
      .description {
        font-weight: 700;
        color: #03a3bd;
      }
      .text {
        display: block;
        overflow-wrap: break-word;
      }
    }
    .pack {
      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
.slider {
  > div {
    .uk-slider-items {
      li {
        .picture {
          position: relative;
          display: block;
          overflow: hidden;
          max-width: 34rem;
          width: 100%;
          height: 25rem;
          picture {
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
