const druh = {
  15: 'Rodinný',
  16: 'Vila',
  560: 'Chata',
  188: 'Jiný',
  112: "1+kk",
  113: "1+1",
  122: "2+kk",
  123: "2+1",
  125: "3+kk",
  126: "3+1",
  132: "4+kk",
  133: "4+1",
  135: "5+kk",
  136: "5+1",
  137: "6",
  33: "pro bydlení",
  34: "pro komerční výstavbu",
  31: "zemědělská půda",
  38: "les",
  32: "zahrada",
  35: "rybníky",
  36: "ostatní",
  27: "kanceláře",
  28: "sklady",
  25: "výroba",
  21: "obchodní prostory",
  221: "restaurace",
  222: "ubytování",
  23: "zemědělské objekty",
  224: "jiné",
  17: "činžovní dům",
  41: "garáž",
  410: "garážové stání",
  420: "vinný sklep",
  42: "ostatní"
};

const vlastnictvi = {
  1: "Osobní",
  2: "Družstevní",
  4: "Jiné"
};

const meny = {
  1: "v kč",
  4: "kč/m2",
  11: "eur",
  44: "eur/m2",
};

const typ = {
  6: "Domy a chaty",
  4: "Byty",
  3: "Pozemky",
  1: "Komerční",
  11: "Ostatní"
};

const pravniVady = {
  pakt01: "Dluh vůči společenství vlastníků",
  pakt02: "Exekuce",
  pakt03: "Insolvence",
  pakt04: "Předkupní právo",
  pakt05: "Hypotéka",
  patk06: "Jiné zástavní právo",
  pakt07: "Zákaz převodu nemovitosti",
  pakt08: "Spor o vlastnictví nemovitosti",
  pakt10: "Jiné",
  pakt11: "Jaké jiné:",
};

const faktickeVady = {
  fakt01: "Poškozená střecha",
  fakt02: "Poškození nosných konstrukcí",
  fakt03: "Nadměrná (vzlinající) vlhkost",
  fakt04: "Nefunkční elektroinstalace",
  fakt05: "Přerušený přívod vody/plynu<",
  fakt07: "Omezená přístupová cesta",
  fakt09: "Jiné",
  fakt10: "Jaké jiné",
}

const vecneBremeno = {
  cakt01: "Služebnost inženýrské sítě",
  cakt02: "Opora cizí stavby",
  cakt03: "Právo na svod dešt. vody",
  cakt04: "Služebnost stezky, průhonu a cesty",
  cakt05: "Právo pastvy",
  cakt06: "Užívací právo",
  cakt07: "Používací právo",
  cakt08: "Služebnost bytu",
}

const energ = {
  1: "A - Mimořádně úsporná",
  2: "B - Velmi úsporná",
  3: "C - Úsporná",
  4: "D - Méně úsporná",
  5: "E - Nehospodárná",
  6: "F - Velmi nehospodárná",
  7: "G - Mimořádně nehospodárná"

}

const stav = {
  1: "Výborný",
  2: "Dobrý",
  3: "Nutná rekonstrukce",
  4: "Špatný",
  5: "Po rekonstrukci",
  6: "Novostavba",
  7: "Ve výstavbě",
}

const postavenoz = {
  1: 'cihla',
  2: 'panel',
  3: 'dřevostavba',
  35: 'smíšené',
  10: 'jiné'
}

const poloha = {
  1: 'řadový',
  2: 'rohový',
  4: 'samostatný',
  25: 'dvojdomek',
  26: 'přízemí',
  27: 'patrový',
  28: 'dvougenerační',
  29: 'komplex budov'
}

const vytapeni = {
  1: 'lokální plynové',
  2: 'lokální tuhá',
  3: 'lokální elektrické',
  8: 'jiné',
}

const inziste = {
  0: 'ne',
  1: 'blizko',
  2: 'ano'
}

const odpad = {
  0: 'ne',
  1: 'veřejná kanalizace',
  2: 'ČOV pro celý objekt',
  3: 'septik',
  4: 'jímka'
}

export default {
  druh,
  vlastnictvi,
  meny,
  typ,
  vecneBremeno,
  faktickeVady,
  pravniVady,
  energ,
  stav,
  postavenoz,
  poloha,
  vytapeni,
  inziste,
  odpad
}
