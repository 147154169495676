<template>
  <div class="pack">
  <p v-if="step.uzit_plocha"><span class="description">Užitná plocha</span><span class="text">{{ step.uzit_plocha }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Užitná plocha</span><span class="text">- m<sup>2</sup></span></p>

  <p v-if="step.plochapozem"><span class="description">Plocha pozemku</span><span class="text">{{ step.plochapozem }} m<sup>2</sup></span></p>
  <p v-else><span class="description">Plocha pozemku</span><span class="text">- m<sup>2</sup></span></p>


  <p v-if="step.stav"><span class="description">Stav</span><span class="text">{{ getStav(step.stav) }}</span></p>
  <p v-else><span class="description">Stav</span><span class="text">-</span></p>

  <p v-if="step.postavenoz"><span class="description">Postaveno z</span><span class="text">{{ getPostavenoz(step.postavenoz) }}</span></p>
  <p v-else><span class="description">Postaveno z</span><span class="text">-</span></p>

  </div>
</template>

<script>
import ciselniky from "../../../libs/ciselniky";

export default {
  name: "zadanyTyp-11",
  props:['step'],
  methods: {
    getStav(id){
      return ciselniky.stav[id];
    },
    getPostavenoz(id){
      return ciselniky.postavenoz[id];
    },
  }
}
</script>

<style scoped lang="less">
section.home-content{
  .container{
    padding: 0 40px;
    p{
      text-align: left;
      margin-bottom: 2rem;
      .description{
        font-weight: 700;
        color: #03A3BD;
      }
      .text{
        display: block;
      }
    }
    .pack{
      p{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>